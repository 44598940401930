'use client'

import Script from 'next/script'

declare global {
  interface Window {
    ritaChatbotConfiguration: {
      configurationId: string
    }
  }
}

export function Chatbot() {
  return (
    <>
      <Script id="ritaChatbotConfiguration">{`window.ritaChatbotConfiguration = {configurationId: '604813363af1650008135f12'}`}</Script>
      <Script id="ritaChatbot" src="https://chat.ritabyaire.com/chatbot.js" strategy="lazyOnload" />
    </>
  )
}
