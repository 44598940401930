'use client'

import '@xstate/react'

import { useLayoutEffect, useRef } from 'react'
import { createActorContext } from '@xstate/react'
import Cookies from 'js-cookie'
import { usePathname } from 'next/navigation'
import { assign, createMachine } from 'xstate'

import { Theme } from '@/data/shared'

const globalAppMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QHEA2B7ARgQ1QAgEEAHIgOgFksBLVMPAO2wDdSBhDWSAYgBV0ootPAFtqQxkwDaABgC6iUEXSwqAFyrp6CkAA9EAJgCM+0gA5DANgAs0i-oCsAGhABPRNNL2r+gJwXTAOz2AL7BzmhYuIQkFGJ0Emwc3ADyRGD0InEMzDLySCBKKuqa2noIAMw+pqSVplblQc5uCPpWPqSG9kH2+tKm5QMDVqHhGDj4xGSUmDTxzKSp6dzsynSiM+I5ctqFahpa+WWVAZ52Tq6IhobtAaY+tg4jIBHj0VNZCYv03HwCQuuzbJSbb5XbFA6gMr2OyeW6GaQ+AJWYzwnxNRDlezteEWAIBPqDQZPF5RSakADKYAAxuDYFwCBAIHhODT9nhVOg8KgqLBVLkdso9iVDogALQDDwDLENc7NCzlUgWCzSQwBTp9W73CzEsakmKU1maWCkAAS2FgzOptK47DA2AATpbDfQLdzefzQYLwaUxVYumZ7JjDHVAgi+uiEJ1qkq7gF9KZ-DHhmFnrqJvqrftjWaLSzrZTVHgAGboKkAV04TLz+w9ii9+x9CHF-VI+nK9UaFwq5lI0nK1ns-UJAx1kXTZANtNN5qd+bAhfL9vt6UL1c0tYK9eFkLFVxMrWVPhlEfKPb7A6Hw9HrzJk6z09zmaNNtoDuLpYrkFnNZBdaKDZFJt4ROK41UCWVLh7ftKn0IxrkMGw7GvPUJyfF0H2-Z8TSoCA6DXF0gQ3MEAJ3Jtbg8BCemkaQ43bforAjfQAgsUgrAsLwfB6I9eiPZDxwpNDsxnfC6XJAALdAAHdMIIiQiK3CFdDFPETHsexKLbBw7mkBiu1PQxe37bw2O8AIiRTEl+IAdTAVAqXQYQ6GwegqGEbBwVIABVegiFQbAXG4AgXLc8E8F8-zIHk-9tyUpt4xY9UunxHT7jqCM6g8WwfCMeUETU+M+LeUgbLshynOC9z9jYMTqQAay4KKhUUspm2qP1z0qdt5R8QwI3xExVXsHSjBsMydMKskSvsxy8Gc1zKs0aq6oaww8j-JrG1FBCDKPa5rFMBwsU6PrpAGoJhoQmjynGiy0yKk07Vwx0cHtUgADUeSoTBaC4bDcLwGrsCevAXsa71AORTLg1MIb+yG4MrACCMlROTjLHMLL4SYiaYgeoGwGeh1TRw3D6C4cSpIBx6CZBh0wZI2LEM8fQlTaQdOmVFn0pojoEKMI8GksWxQhTeh0Fw+B8kst4BWi5qxUCBK-V6Gi2yseiI1FWCFR69tpC8fX8XbHH3g2OYmFljbAMsKwzBth4IKbBNSE4-EbAPHxPZVE3YjNoFElWCBLfB0imJY-xMSPTtmiYgzTDqK4-UvEdbrHIrpkBT40m+IPPTlxssRYz2jHAiM+c8DnHlTm8M2dSX1pD2LRQO6oNOo2j1fbTW7lYww9dAwNg37H27yNUgADlOREvBNDC7AYGDhmWpMl21Jo8wHE1na++8SxrphqOQmrlCBLrjCRMXmKWtaEw2kPY8u18bFsp0tV4MQn2prK2aKsb4ir99P2VisNupdUqL1LsXgTgqkugdfwcZ9Cf1stNcq80PLeXCgFXODcl5igGCxQMuUbA0WLhYPqFh2hKgjuUNsdhbhH1GGnSayDv5zRClVAACn5LBl95ZAQQjULKypAzuyyuQyhMYBi0KYjDJBpUZpsIWvQJaVJaq8M2jQ+wzMQzqwTLBHSuk5Ttgrn3QI5hyjBkMD7PGwMXrqMAlpF2fQaLeEjurCBzRBweD9KqFUPQTKnmsdTQmr0PoqG+mAexpFrpF2cUiNsWJ3F9SsLbWCdR-BPyGhQoJ+MQnE0ZOkKJsVoQKhytxNJeJwxdiRKk+MbEDqeyGgEEWwQgA */
  createMachine(
    {
      id: 'Global App',
      tsTypes: {} as import('./GlobalAppContext.typegen').Typegen0,

      schema: {
        context: {} as {
          mobileNavOpen: boolean
          theme: Theme
          sections: string[]
          currentSection: string | undefined
          focusedSection: string | undefined
          sectionNavHidden: boolean
          playWelcomeAnimation: boolean
        },
        events: {} as
          | { type: 'Open mobile nav' }
          | { type: 'Close mobile nav' }
          | { type: 'Toggle mobile nav' }
          | { type: 'Add section to list'; section: string }
          | { type: 'Clear sections list' }
          | { type: 'Set focused section'; section: string }
          | { type: 'Clear focused section' }
          | { type: 'Set current section'; section: string }
          | { type: 'Hide sections nav' }
          | { type: 'Show sections nav' }
          | { type: 'Animation played' }
          | { type: 'Hide header bar' }
          | { type: 'Show header bar' },
      },

      context: {
        mobileNavOpen: false,
        theme: 'dark',
        sections: [],
        currentSection: undefined,
        focusedSection: undefined,
        sectionNavHidden: false,
        playWelcomeAnimation: true,
      },

      states: {
        'Mobile nav': {
          states: {
            Closed: {
              on: {
                'Toggle mobile nav': {
                  target: 'Opened',
                  actions: 'Toggle mobile nav',
                },
                'Open mobile nav': {
                  target: 'Opened',
                  actions: 'Open mobile nav',
                },
              },
            },

            Opened: {
              on: {
                'Close mobile nav': {
                  target: 'Closed',
                  actions: 'Close mobile nav',
                },
                'Toggle mobile nav': {
                  target: 'Closed',
                  actions: 'Toggle mobile nav',
                },
              },
            },
          },

          initial: 'Closed',
        },

        Sections: {
          states: {
            'No sections on page': {},

            'Has sections': {
              on: {
                'Clear sections list': {
                  target: 'No sections on page',
                  actions: 'Clear sections list',
                },

                'Set focused section': {
                  target: 'Has sections',
                  internal: true,
                  actions: 'Set focused section',
                },

                'Set current section': {
                  target: 'Has sections',
                  internal: true,
                  actions: 'Set current section',
                },

                'Clear focused section': {
                  target: 'Has sections',
                  internal: true,
                  actions: 'Clear focused section',
                },

                'Hide sections nav': {
                  target: 'Has sections',
                  internal: true,
                  cond: 'sections nav is not hidden',
                  actions: 'Hide sections nav',
                },

                'Show sections nav': {
                  target: 'Has sections',
                  cond: 'sections nav is hidden',
                  actions: 'Show sections nav',
                  internal: true,
                },
              },
            },
          },

          initial: 'No sections on page',

          on: {
            'Add section to list': {
              target: '.Has sections',
              cond: 'Section not in list',
              actions: 'Add section',
            },
          },
        },

        'Welcome animation': {
          states: {
            Unplayed: {
              on: {
                'Animation played': {
                  target: 'Played',
                  actions: 'Mark animation as played',
                },
              },
            },

            Played: {},
            Check: {
              always: [
                {
                  target: 'Unplayed',
                  cond: 'Should play animation',
                },
                'Played',
              ],
            },
          },

          initial: 'Check',
        },

        'Header bar': {
          states: {
            Visible: {
              on: {
                'Hide header bar': 'Hidden',
              },
            },

            Hidden: {
              on: {
                'Show header bar': 'Visible',
              },
            },
          },

          initial: 'Visible',
        },
      },

      type: 'parallel',
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      guards: {
        'Section not in list': ({ sections }, { section }) => !sections.includes(section),
        'sections nav is not hidden': ({ sectionNavHidden }) => !sectionNavHidden,
        'sections nav is hidden': ({ sectionNavHidden }) => sectionNavHidden,
        'Should play animation': ({ playWelcomeAnimation }) => playWelcomeAnimation,
      },
      actions: {
        'Open mobile nav': assign((_ctx, _evt) => ({ mobileNavOpen: true })),
        'Close mobile nav': assign((_ctx, _evt) => ({ mobileNavOpen: false })),
        'Toggle mobile nav': assign((ctx, _evt) => ({ mobileNavOpen: !ctx.mobileNavOpen })),

        'Add section': assign(({ sections, currentSection }, { section }) => ({
          sections: [...sections, section],
          currentSection: currentSection || section,
        })),
        'Clear sections list': assign((_ctx, _evt) => ({
          sections: [],
          currentSection: undefined,
          focusedSection: undefined,
        })),
        'Hide sections nav': assign((_ctx, _evt) => ({ sectionNavHidden: true })),
        'Show sections nav': assign((_ctx, _evt) => ({ sectionNavHidden: false })),
        'Set current section': assign((_ctx, { section }) => ({ currentSection: section })),
        'Set focused section': assign((_ctx, { section }) => ({ focusedSection: section })),
        'Clear focused section': assign((_ctx, _evt) => ({ focusedSection: undefined })),
        'Mark animation as played': assign((_ctx, _evt) => {
          Cookies.set('welcome-overlay-last-played', new Date().toISOString(), { sameSite: 'strict' })
          return { playWelcomeAnimation: false }
        }),
      },
    },
  )

export const GlobalAppContext = createActorContext(globalAppMachine)

function RouteObserver({ children }: { children: React.ReactNode }) {
  const previousPathname = useRef<string | null>(null)
  const pathname = usePathname()
  const [, send] = GlobalAppContext.useActor()

  useLayoutEffect(() => {
    if (previousPathname.current === pathname) {
      return
    }

    previousPathname.current = pathname
    send({ type: 'Clear sections list' })
  }, [pathname, send])

  return <>{children}</>
}

interface GlobalAppProps {
  children: React.ReactNode
}

export function GlobalAppProvider({ children }: GlobalAppProps) {
  return (
    <GlobalAppContext.Provider>
      <RouteObserver>{children}</RouteObserver>
    </GlobalAppContext.Provider>
  )
}
