import { SVGAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

export const HeartIcon = ({ className, fill, ...props }: SVGAttributes<SVGElement>) => {
  return (
    <svg
      data-cy="heart-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 22"
      fill="none"
      className={twMerge('h-6 w-6 stroke-current', className)}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.7"
        d="m13 20.3-9.5-8.5c-5.1-5.2 2.5-15 9.5-7 7-8 14.6 1.9 9.5 7L13 20.3Z"
        fill={fill}
      />
    </svg>
  )
}
