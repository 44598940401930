'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { GlobalAppContext } from '@/components/GlobalAppContext'
import { useBreakpointEffect } from '@/utils/tailwind'
import { usePrevious } from '@/utils/usePrevious'
import { PreventScroll } from '../PreventScroll'
import { MobileNavButton } from './MobileNavButton'

interface MobileNavigationProps {
  children?: React.ReactNode
  extras?: React.ReactNode
}

export function MobileNavigation({ children, extras }: MobileNavigationProps) {
  const [, send] = GlobalAppContext.useActor()
  const active = GlobalAppContext.useSelector((state) => state.matches('Mobile nav.Opened'))

  const pathname = usePathname()
  const previousPathname = usePrevious(pathname)

  useBreakpointEffect('md+', (match) => {
    if (match) {
      send('Close mobile nav')
    }
  })

  useEffect(() => {
    if (previousPathname !== pathname) {
      send('Close mobile nav')
    }
  }, [pathname, previousPathname, send])

  return (
    <>
      <MobileNavButton />
      <div
        data-cy="mobile-nav-items"
        className={twMerge(
          'portrait:justify-even absolute inset-0 h-[100dvh] w-full flex-col justify-between overflow-y-scroll bg-neutral-850 pb-8 pt-16 md+:hidden',
          active ? 'flex' : 'hidden',
        )}
      >
        <ul
          data-cy="mobile-nav-items"
          className="relative z-20 flex flex-col justify-stretch bg-neutral-850 px-10 [&_a]:py-3 [&_button]:py-3"
        >
          <>{children}</>
        </ul>
        <div className="mx-10 space-y-6 border-t border-t-current pt-8">{extras}</div>
      </div>
      {active && <PreventScroll scrollTo={0} snapBack />}
    </>
  )
}
