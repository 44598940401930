import Link from 'next/link'
import { useSelectedLayoutSegment } from 'next/navigation'

import { HeartIcon } from '../icons/HeartIcon'

interface NavigationLinkProps extends React.ComponentProps<typeof Link> {
  href: string
  children?: React.ReactNode | React.ReactNode[]
  className?: string
}

export function NavigationLink({ href, children: sourceChildren, className, ...props }: NavigationLinkProps) {
  let children = sourceChildren

  if (Array.isArray(sourceChildren)) {
    const [title, ...rest] = sourceChildren
    if (typeof title === 'string' && title.startsWith('<3')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      children = [
        <div key={0} className="relative flex h-5 items-center gap-x-2 md+:w-6">
          <span className="md+:sr-only">{title.substring(2)}</span>
          <HeartIcon fill="currentColor" className="-mt-1 inline md+:absolute" aria-label="My Favourites" />
        </div>,
        ...rest,
      ]
    }
  }

  const segment = useSelectedLayoutSegment()
  const current = typeof href === 'string' && segment === href.slice(1)
  return (
    <Link className={className} href={href} aria-current={current} {...props}>
      {children}
    </Link>
  )
}
