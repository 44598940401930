'use client'

import { Fragment } from 'react'
import { Menu } from '@headlessui/react'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { NavigationNodeTree } from '@/data/getMainNavigation'

interface NavigationMenuProps {
  children: React.ReactNode
  className: string
  active?: boolean
  navigationChildren: NavigationNodeTree[number]['children']
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  expandOnHover?: boolean
}

export function NavigationMenu({
  children,
  className,
  active,
  navigationChildren,
  onMouseEnter,
  onMouseLeave,
  expandOnHover,
}: NavigationMenuProps) {
  const pathname = usePathname()
  const variants: Variants = {
    out: { opacity: 0, y: -8 },
    in: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 400, damping: 20, delayChildren: 0.1, staggerChildren: 0.1 },
    },
    exit: { opacity: 0, y: -8, transition: { delay: 0.2, staggerChildren: 0.1 } },
  }
  const childVariants: Variants = {
    out: { opacity: 0, y: -4 },
    in: { opacity: 1, y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
    exit: { opacity: 0, y: -4, transition: { duration: 0.3, ease: 'easeIn' } },
  }

  return (
    <Menu as="div" className="inline-block md+:relative">
      {({ open, close }) => (
        <div
          onMouseLeave={() => {
            if (expandOnHover && open) {
              close()
            }
            onMouseLeave?.()
          }}
        >
          <Menu.Button
            className={className}
            onMouseEnter={({ currentTarget }) => {
              if (expandOnHover && !active) {
                currentTarget.click()
              }
              onMouseEnter?.()
            }}
          >
            {children}
          </Menu.Button>
          <div className="pointer-events-none right-0 flex w-full origin-top-right justify-end md+:absolute md+:-right-2 md+:w-screen/2 lg:right-0">
            <div
              className={twMerge(
                'pointer-events-auto grid grid-cols-1 transition-all duration-500 md+:w-auto',
                open ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]',
              )}
            >
              <AnimatePresence>
                {open && (
                  <Menu.Items
                    static
                    as={motion.div}
                    className="mb-2 flex flex-col items-start overflow-hidden bg-neutral-850/80 backdrop-blur focus:outline-none md+:mt-2 md+:items-end md+:gap-5 md+:rounded-br-3xl md+:rounded-tl-3xl md+:pb-5 md+:pl-5 md+:pr-2 md+:pt-4 md+:drop-shadow-lg md+:backdrop-brightness-90 lg:px-0"
                    layout="position"
                    variants={variants}
                    initial="out"
                    animate="in"
                    exit="exit"
                  >
                    {navigationChildren.map(({ id, title, uri }) => {
                      const current = pathname === `/${uri}`
                      return (
                        <Menu.Item key={id} as={Fragment}>
                          {({ active }) => (
                            <motion.span layout="position" variants={childVariants}>
                              <Link
                                href={`/${uri}`}
                                className={twMerge(
                                  className,
                                  'relative cursor-pointer whitespace-nowrap pl-5 text-left before:absolute before:bottom-1/6 before:left-5 before:right-0 before:z-10 before:block before:border-b-2 before:border-b-neutral-50 before:transition-opacity md+:pl-0 md+:before:bottom-0 md+:before:left-0 md+:before:right-4 lg:before:left-5 lg:before:right-5',
                                  active || current
                                    ? current
                                      ? 'before:opacity-100'
                                      : 'before:opacity-40'
                                    : 'before:opacity-0',
                                )}
                              >
                                {title}
                              </Link>
                            </motion.span>
                          )}
                        </Menu.Item>
                      )
                    })}
                  </Menu.Items>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      )}
    </Menu>
  )
}
