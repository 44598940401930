'use client'

import { PropsWithChildren, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { GlobalAppContext } from '../GlobalAppContext'

export function HeaderBarCarrier({ children }: PropsWithChildren) {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const hideHeaderBar = GlobalAppContext.useSelector((state) => state.matches('Header bar.Hidden'))

  useEffect(() => {
    if (hideHeaderBar) {
      scrollToTop()
      const timeout = setTimeout(scrollToTop, 500)
      return () => clearTimeout(timeout)
    }
  }, [hideHeaderBar])
  return (
    <>
      <div
        className={twMerge(
          'absolute z-70 h-16 w-full duration-700 md:h-24',
          hideHeaderBar ? 'opacity-0 -translate-y-full' : 'opacity-100 translate-y-0',
        )}
      >
        {children}
      </div>
    </>
  )
}
