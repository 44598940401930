import { useEffect, useState } from 'react'

interface PreventScrollProps {
  scrollTo?: number | false
  snapBack?: boolean
}

export function PreventScroll({ scrollTo = false, snapBack }: PreventScrollProps) {
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    if (scrollTo !== false && typeof scrollTo === 'number') {
      setScrollY(window.scrollY)
      setTimeout(() => {
        window.scrollTo(0, scrollTo)
      }, 1000)
    }

    return () => {
      if (snapBack && typeof scrollTo === 'number') {
        window.scrollTo(0, scrollY)
      }
    }
  }, [scrollTo, scrollY, snapBack])

  return (
    <style jsx global>{`
      body,
      html {
        overflow: hidden;
      }
    `}</style>
  )
}
