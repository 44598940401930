'use client'

import { createActorContext } from '@xstate/react'
import { assign, createMachine } from 'xstate'

export const favouritesMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QDECGA3A9gVwE4EsAXOAAgFlUBjAC3wDswA6AVTvqP1QBt9ZISAyoVTEAxABlMqCCQDCeXGDqESaLHiJwA2gAYAuolAAHTLA6Y6hkAA9EAZgBsjAJwBGAOzP3Du57vOAFgBWOwAaEABPRADXACYXHQdYoIBfFPC1HAJiWHIqWgZGAEk2Qk4ePghRAEEIGQAVTFUMLM1YXQMkEBMzMosrWwQA2J1GIJ1XAIdE5wcg9yDncKiEWIAOAMZ3NbjU9JBMjRy8mnomEo5uXkhRACUwAFtMdDASADNcTAfm9WztfSsPXMli6g38a0YI2CayCDlm80Wy0QQSC8R0sVcqLsAWcdlhsWcaX2dEwEDgVkOf1yFFODEBpmBA0QAFpXK5GDo1iNYo4RtMglMlpFEK4vJCdhjAlzhq41ms0hkWkdSDSCkxWOwyldKoJhMR6b18P1QfZnIwAolkt4Cf5gmFhasxTyAr49orfm0TmriqVytcIAbGSbHey7bskUM8YwfOtdkSUkA */
    id: 'Favourites Machine',

    context: {
      favourites: [] as string[],
    },

    initial: 'Uninitialised State',

    states: {
      'Uninitialised State': {
        on: {
          'Load Current Favourites': {
            target: 'Initialised',
            actions: 'Initialise Favourites',
          },
        },

        invoke: {
          src: 'Read LocalStorage',
        },
      },
      Initialised: {
        on: {
          'Add To Favourites': {
            internal: true,
            actions: ['Add to Favourites', 'Write to LocalStorage'],
          },
          'Remove from Favourites': {
            internal: true,
            actions: ['Remove Favourite', 'Write to LocalStorage'],
          },
        },
      },
    },

    schema: {
      events: {} as
        | { type: 'Load Current Favourites'; favourites: string[] }
        | { type: 'Add To Favourites'; id: string }
        | { type: 'Remove from Favourites'; id: string },
    },

    predictableActionArguments: true,
    preserveActionOrder: true,
    tsTypes: {} as import('./FavouritesContext.typegen').Typegen0,
  },
  {
    actions: {
      'Initialise Favourites': assign((_ctx, { favourites }) => ({
        favourites,
      })),
      'Add to Favourites': assign((ctx, { id }) => ({
        favourites: [...ctx.favourites, id],
      })),
      'Remove Favourite': assign((ctx, { id }) => ({
        favourites: ctx.favourites.filter((fave) => fave !== id),
      })),
    },
    services: {},
    guards: {},
    delays: {},
  },
)

export const FavouritesContext = createActorContext(favouritesMachine)

export const FavouritesProvider = FavouritesContext.Provider
