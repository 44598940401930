'use client'

import { useState } from 'react'
import { useScroll } from 'framer-motion'
import { ArrowUpIcon } from 'lucide-react'

import { cn } from '@/lib/utils'

export const BackToTop = () => {
  const [show, setShow] = useState(false)

  const { scrollY } = useScroll()

  scrollY.on('change', (v) => {
    setShow(v > document.documentElement.clientHeight)
  })

  return (
    <div className={cn('fixed bottom-8 left-8 z-50 transition-transform', !show && 'translate-y-20')}>
      <button
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }}
        className="flex aspect-square w-10 shrink-0 cursor-pointer items-center justify-center rounded-full border border-solid border-neutral-850 bg-neutral-50 text-neutral-850 shadow-md"
        aria-label="Back to top"
        tabIndex={show ? 0 : -1}
      >
        <ArrowUpIcon className="pointer-events-none h-5 w-5" />
        <span className="sr-only">Back to top</span>
      </button>
    </div>
  )
}
