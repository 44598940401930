'use client'

import { useState } from 'react'
import { useSelectedLayoutSegments } from 'next/navigation'
import { twMerge } from 'tailwind-merge'

import { NavigationNodeTree } from '@/data/getMainNavigation'
import { GlobalAppContext } from '../GlobalAppContext'
import { ActiveIndicator } from './NavigationActiveIndicator'
import { NavigationLink } from './NavigationLink'
import { NavigationMenu } from './NavigationMenu'

interface NavigationItemsProps {
  className: string
  activeClassName?: string
  groupId: string
  navigationItems: NavigationNodeTree
  expandOnHover?: boolean
}

export function NavigationItems({
  className,
  activeClassName,
  groupId,
  navigationItems,
  expandOnHover,
}: NavigationItemsProps) {
  const mobileNavOpen = GlobalAppContext.useSelector((state) => state.matches('Mobile nav.Opened'))
  const immediate = groupId === 'mobile-nav' && !mobileNavOpen

  const [hovered, setHovered] = useState<string | undefined>()

  const segments = useSelectedLayoutSegments()

  return navigationItems.map(({ id, title, uri, children }) => {
    const current =
      !!segments?.[0]?.length &&
      (segments[0] === uri?.split('/')[0] || children.some((child) => child.uri.split('/')[0] === segments[0]))
    const active = hovered === id
    return (
      <li key={id} className="relative flex" onMouseLeave={() => setHovered(undefined)}>
        {children?.length > 0 && (
          // Href should be undefined in this situation
          <NavigationMenu
            className={twMerge('relative cursor-default text-right', className)}
            navigationChildren={children}
            active={active}
            expandOnHover={expandOnHover}
            onMouseEnter={() => setHovered(id)}
          >
            {title}
            <ActiveIndicator
              className={activeClassName}
              groupId={groupId}
              active={active}
              current={current}
              hovered={hovered}
              immediate={immediate}
            />
          </NavigationMenu>
        )}
        {Number(children?.length) === 0 && uri && (
          <NavigationLink className={className} href={`/${uri}`} onMouseEnter={() => setHovered(id)}>
            {title}
            <ActiveIndicator
              className={activeClassName}
              groupId={groupId}
              active={active}
              current={current}
              hovered={hovered}
              immediate={immediate}
            />
          </NavigationLink>
        )}
      </li>
    )
  })
}
