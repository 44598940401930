'use client'

import { useEffect } from 'react'

export function DisableRightClick() {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    const handleContextmenu = (e: MouseEvent) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])
  return null
}
