'use client'

import { motion } from 'framer-motion'

interface ActiveIndicatorProps {
  className?: string
  groupId: string
  current?: boolean
  active?: boolean
  hovered?: string
  immediate?: boolean
}

export function ActiveIndicator({ className, groupId, current, active, hovered, immediate }: ActiveIndicatorProps) {
  return (
    <>
      {current ? (
        <motion.div
          layoutId={`${groupId}-current`}
          style={{ scale: hovered && active ? 1 : 0.8 }}
          transition={immediate ? { duration: 0 } : { duration: 1, type: 'tween' }}
        />
      ) : null}
      {active || (!hovered && current) ? (
        <motion.div
          layoutId={`${groupId}-hover`}
          className={className}
          style={{ opacity: current ? 1 : 0.4 }}
          transition={immediate ? { duration: 0 } : { stiffness: 250, type: 'spring', damping: 25 }}
        />
      ) : null}
    </>
  )
}
