import create from '@kodingdotninja/use-tailwind-breakpoint'
import defaultConfig from 'tailwindcss/defaultConfig'
import { z } from 'zod'

import screens from '../../tailwind.config.screens.json'

const defaultScreensSchema = z.object({
  sm: z.string(),
  md: z.string(),
  lg: z.string(),
  xl: z.string(),
  '2xl': z.string(),
})

export const allScreens = {
  ...defaultScreensSchema.parse(defaultConfig.theme?.screens),
  ...screens,
}

// eslint-disable-next-line @typescript-eslint/unbound-method
export const { useBreakpoint, useBreakpointValue, useBreakpointEffect } = create(allScreens)
