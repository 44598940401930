'use client'

import { twMerge } from 'tailwind-merge'

import { GlobalAppContext } from '@/components/GlobalAppContext'

interface HamburgerButtonProps {
  className?: string
}

const mobileNavIconClosed = (
  <svg data-cy="mobile-nav-closed-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="26" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.286"
      d="M30.857 1.57H1.143M30.857 13H1.143M30.857 24.426H1.143"
    />
  </svg>
)

const mobileNavIconOpen = (
  <svg
    data-cy="mobile-nav-open-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1431 0.855469L0.857422 23.1412"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.857422 0.855469L23.1431 23.1412"
      stroke="currentColor"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export function MobileNavButton({ className }: HamburgerButtonProps) {
  const [, send] = GlobalAppContext.useActor()
  const active = GlobalAppContext.useSelector((state) => state.matches('Mobile nav.Opened'))

  const handleClick = () => {
    send('Toggle mobile nav')
  }

  return (
    <button
      data-cy="mobile-nav-button"
      className={twMerge('relative z-30 flex h-10 w-10 items-center justify-center rounded md+:hidden', className)}
      onClick={handleClick}
    >
      {active ? mobileNavIconOpen : mobileNavIconClosed}
    </button>
  )
}
