'use client'

import { PropsWithChildren } from 'react'

import { FavouritesContext } from './FavouritesContext'

export const FavouritesLocalStorageProvider = ({ children }: PropsWithChildren) => (
  <FavouritesContext.Provider
    options={{
      actions: {
        'Write to LocalStorage': ({ favourites }) => {
          if (favourites) {
            localStorage.setItem('favourites', JSON.stringify(favourites))
          }
        },
      },
      services: {
        'Read LocalStorage': (_ctx) => (sendToFavourites) => {
          const defaultState: string[] = []
          const storedFavourites = localStorage.getItem('favourites')
          const persistedState = (storedFavourites && (JSON.parse(storedFavourites) as string[])) || defaultState
          sendToFavourites({ type: 'Load Current Favourites', favourites: persistedState })
        },
      },
    }}
  >
    {children}
  </FavouritesContext.Provider>
)
